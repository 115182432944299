import React from 'react'
import { Box, Text } from '@chakra-ui/react'
import { PageLayout } from '../components/PageLayout'
import { Hero } from '../components/Hero'

export const Head = () => (
    <>
        <title>BRPC Outdoor Recreation Website | Orientier Proposal Hub</title>
        <meta
            name="description"
            content="Resources illuminating a proposal to build a new website promoting outdoor recreation in Berkshire County, Massachusetts"
        />
        <meta name="robots" content="noindex" />
    </>
)

const hero = (
    <Hero
        heading="Berkshire County Outdoor Recreation Website Project"
        subheading="A Proposal by Orientier for Berkshire Regional Planning Commission"
    />
)

const IndexPage = () => (
    <PageLayout hero={hero}>
        <Box fontSize="md" maxWidth="container.md" mx="auto" p={8}>
            <Text>Welcome, and thanks for your interest. We hope the resources here will illuminate our proposal.</Text>
            <Text>
                We are using this space to showcase proofs of concept, and plan to transition it into an interview
                supplement, discovery platform, and beta testing tool if the opportunity arises.
            </Text>
        </Box>
    </PageLayout>
)

export default IndexPage
